import { User } from "oidc-client-ts";
import { oidcConfig } from "./userManager";

/*
  Syncronously gets the oidc-client-ts User object from session storage
  As the UserManager's async version is not always ideal
*/

const getUser = () => {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`
  );

  if (!oidcStorage) {
    return null;
  }

  const user = User.fromStorageString(oidcStorage);
  return user;
};

export default getUser;
