import getUser from "./getUser";
import { userManager } from "./userManager";

/*
Returns true if:
- there is a User in storage
- the token has not expired
- the user has at least one role

Intended for things like auth on enter / route change once logged in.
*/

export const isAuthenticated = (role) => {
  let authenticated = false;

  const user = getUser();
  if (!user) {
    return false;
  }

  const expired = Date.now() > (user.expires_at || 0) * 1000;

  const { roles } = user.profile;
  const validRoles = role ? !!roles && roles.includes(role) : !!roles;

  if (!expired && validRoles) {
    authenticated = true;
  } else {
    userManager.removeUser();
  }

  return authenticated;
};
