import { WebStorageStateStore, UserManager } from "oidc-client-ts";
import { OIDC_AUTH_AUTHORITIES } from "./constants";

export const oidcConfig = {
  client_id: window?.SSCL?.SENTINEL_CLIENT_ID,
  authority:
    OIDC_AUTH_AUTHORITIES[window?.SSCL?.SENTINEL_ENVIRONMENT || "development"],
  redirect_uri: `${window.location.origin}/auth`,
  silent_redirect_uri: `${window.location.origin}/auth`,
  response_type: "code",
  scope: "openid profile email",
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  automaticSilentRenew: false,
  extraQueryParams: {
    kc_idp_hint: "ciam",
  },
};

export const userManager = new UserManager(oidcConfig);
