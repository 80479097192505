import { createAuthHeaders, getTokenValuesForHeaders } from "./authHeaders";
import getUser from "./getUser";
import { hasRole } from "./hasRole";
import { isAuthenticated } from "./isAuthenticated";
import { timeouts } from "./timeouts";
import { userManager, oidcConfig } from "./userManager";

export {
  createAuthHeaders,
  getTokenValuesForHeaders,
  getUser,
  hasRole,
  isAuthenticated,
  timeouts,
  userManager,
  oidcConfig,
};

export default {
  createAuthHeaders,
  getTokenValuesForHeaders,
  getUser,
  hasRole,
  isAuthenticated,
  timeouts,
  userManager,
  oidcConfig,
};
