import React, { Suspense, lazy } from "react";
import Tooltip from "react-tooltip";
import { AuthProvider } from "react-oidc-context";
import { userManager } from "./utilities/security/auth-management";
const ErrorBoundary = lazy(() => import("./components/ErrorBoundary"));
const LayoutWrapper = lazy(() => import("./containers/LayoutWrapper"));
const Routes = lazy(() => import("./Routes"));

const App = () => (
  <Suspense fallback={<div />}>
    <AuthProvider userManager={userManager}>
      <LayoutWrapper>
        <ErrorBoundary>
          <Tooltip
            className="react-tooltip"
            id="tooltip"
            multiline
            effect="solid"
            place="top"
            delayShow={200}
          />
          <Routes />
        </ErrorBoundary>
      </LayoutWrapper>
    </AuthProvider>
  </Suspense>
);

export default App;
