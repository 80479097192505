import getUser from "./getUser";

// 1. sets a timeout to clear auth and log out when the token expires
// 2. sets a timeout to run a callback that warns user of logout and lets them refresh
const setAuthTimeouts = (
  promptCallback,
  expirationCallback,
  timerInMinutes = 5
) => {
  const user = getUser() || { expires_at: 0 };
  const expiration = user.expires_at * 1000;

  // time difference in ms, if 0 or negative will fire right away
  const expirationTimer = Math.max(expiration - Date.now(), 0);
  const promptTimer = Math.max(
    expiration - Date.now() - timerInMinutes * 60 * 1000,
    0
  );

  // clear any existing auth timeouts
  // specifically used to reset timers during silent refresh
  clearAuthTimeouts();

  // expiration timeout
  timeouts.expirationTimeout = setTimeout(() => {
    expirationCallback();
  }, expirationTimer);

  // prompt timeout
  timeouts.promptTimeout = setTimeout(() => {
    promptCallback();
  }, promptTimer);
};

const clearAuthTimeouts = () => {
  if (timeouts.expirationTimeout) {
    window.clearTimeout(timeouts.expirationTimeout);
  }
  if (timeouts.promptTimeout) {
    window.clearTimeout(timeouts.promptTimeout);
  }
};

// This object gets exported instead of setAuthTimeouts alone
// so that the timeouts have a place to live and can be cleared easily.
export const timeouts = {
  setAuthTimeouts,
  clearAuthTimeouts,
  expirationTimeout: null,
  promptTimeout: null,
};
