import getUser from "./getUser";

/*
  Gets tokenType, accessToken, and idToken from sessionStorage and returns them.
  Use for making requests if you want something more DIY than createAuthHeaders.
*/
export const getTokenValuesForHeaders = () => {
  const user = getUser();
  if (!user) return {};
  const { access_token, token_type, id_token } = user;

  return {
    tokenType: token_type,
    accessToken: access_token,
    idToken: id_token,
  };
};

/*
  Takes a config object and returns a headers object extended with the config.
  Use for making requests.
*/
export const createAuthHeaders = (config) => {
  const user = getUser();
  if (!user) return { ...config };
  const { accessToken, tokenType, idToken } = getTokenValuesForHeaders();

  const headers = {
    Authorization: `${tokenType} ${accessToken}`,
    Identity: idToken,
    ...config,
  };

  return headers;
};
